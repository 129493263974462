<template>
  <div class="mt-base">
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">No. Pengajuan</label>
        <vs-input class="w-full" :value="initData.header.no_pengajuan" readonly/>
      </div>
      <div class="vx-col sm:w-6/12 w-full">
        <label class="ml-1 text-xs">Tgl. Pengajuan</label>
        <vs-input class="w-full" :value="initData.header.tgl_pengajuan" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tgl. Mulai Publish</label>
        <vs-input class="w-full" :value="initData.header.tgl_mulai_publish" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Tipe</label>
        <vs-input class="w-full" :value="initData.header.tipe_platform" readonly/>
      </div>
      <div class="vx-col sm:w-4/12 w-full">
        <label class="ml-1 text-xs">Proyek</label>
        <vs-input class="w-full" :value="initData.header.nama_proyek" readonly/>
      </div>
    </div>
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="ml-1 text-xs">Keterangan Pengajuan</label>
        <vs-textarea class="w-full" icon-pack="feather" icon="icon-map-pin" v-model="initData.header.keterangan" readonly/>
      </div>
    </div>

    <template v-if="initData.header.tipe_platform === 'ADVERTISING'">
      <vs-divider class="mt-base">Detail Advertising</vs-divider>
      <div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Tgl. Selesai Publish</label>
            <vs-input class="w-full" :value="initData.header.tgl_selesai_publish" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Biaya</label>
            <v-money class="w-full" :value="initData.header.biaya" readonly/>
          </div>
          <div class="vx-col sm:w-4/12 w-full">
            <label class="ml-1 text-xs">Sisa Saldo</label>
            <v-money class="w-full" :value="initData.header.sisa_saldo" readonly/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-12/12 w-full">
            <label class="ml-1 text-xs">Lokasi</label>
            <div class="w-full flex flex-wrap items-center overflow-x-auto">
              <vs-chip v-for="(item, index) in initData.header.lokasi_list" :key="index">{{ item }}</vs-chip>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import VMoney from '@/views/components/v-money/VMoney'

export default {
  name: 'TabHeader',
  components: {
    VMoney
  },
  computed: {
    storePengajuanMediaShow () {
      return this.$store.state.marketing.pengajuanMediaShow
    },
    initData: {
      get () {
        return this.storePengajuanMediaShow.initData
      },
      set (value) {
        this.$store.commit('marketing/pengajuanMediaShow/SET_INIT_DATA', value)
      }
    }
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
